import {Icon} from '@lightricks/react-design-system';
import PERMISSIONS from '@/config/permissions';
import {RequestedPermission} from '@/hooks/use-dashboard-user-permissions/useDashboardUserPermissions';

type MenuItem = {
  id: string;
  Icon: typeof Icon;
  iconProps: {size: string; appearance: string; name: string};
  localeLabelKey: string;
  link: string;
  requiredPermissionsToDisplay: RequestedPermission[];
  requiredPermissionsToUnlock: RequestedPermission[];
  tag?: string;
  customIsDisplayedFunc?: (params: any) => boolean;
  showUnreadIndicatorFunc?: (params: any) => boolean;
  mobileLocation?: 'main' | 'submenu';
  flagName?: string;
};
const MENU_ITEMS: MenuItem[] = [
  {
    id: 'home',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Navigation-Home-Stroke',
    },
    localeLabelKey: 'components.menu.items.home',
    link: `/`,
    requiredPermissionsToDisplay: [],
    requiredPermissionsToUnlock: [],
    mobileLocation: 'submenu',
  },
  {
    id: 'campaigns',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Navigation-Megaphone',
    },
    localeLabelKey: 'components.menu.items.campaigns',
    link: `/campaigns`,
    requiredPermissionsToDisplay: [],
    requiredPermissionsToUnlock: [PERMISSIONS.CAMPAIGNS.VIEW],
    showUnreadIndicatorFunc: (currentBrand: any) =>
      currentBrand?.hasBrandActionItems,
    mobileLocation: 'main',
  },
  {
    id: 'safe-collab',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Navigation-Shopbag-Shield-Stroke',
    },
    localeLabelKey: 'components.menu.items.safe-collab',
    link: `/vetting/hub`,
    requiredPermissionsToDisplay: [PERMISSIONS.VETTING.VIEW],
    tag: 'Beta',
    requiredPermissionsToUnlock: [],
  },
  {
    id: 'ai-content-generator',
    flagName: 'aiStudio',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Features-MagicWand',
    },
    localeLabelKey: 'components.menu.items.ai-content-generator',
    link: `/ai-studio`,
    requiredPermissionsToDisplay: [],
    requiredPermissionsToUnlock: [],
  },
  {
    id: 'search',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Actions-Search-Generic',
    },
    localeLabelKey: 'components.menu.items.search',
    link: `/search`,
    requiredPermissionsToDisplay: [],
    requiredPermissionsToUnlock: [],
    mobileLocation: 'main',
  },
  {
    id: 'creators',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Features-Profile',
    },
    localeLabelKey: 'components.menu.items.creators',
    link: `/creators`,
    requiredPermissionsToDisplay: [PERMISSIONS.CREATORS.VIEW],
    requiredPermissionsToUnlock: [PERMISSIONS.CREATORS.VIEW],
    mobileLocation: 'main',
  },
  {
    id: 'collaborations',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Navigation-Messages',
    },
    localeLabelKey: 'components.menu.items.collaborations',
    link: `/collaborations`,
    requiredPermissionsToDisplay: [PERMISSIONS.COLLABORATIONS.VIEW],
    requiredPermissionsToUnlock: [PERMISSIONS.COLLABORATIONS.VIEW],
    customIsDisplayedFunc: ({subscription, flags}: any) =>
      flags?.pricingUpdates || !subscription?.isFree,
    showUnreadIndicatorFunc: (currentBrand: any) => {
      return currentBrand?.hasUnreadCollaborations;
    },
    mobileLocation: 'submenu',
  },
  {
    id: 'content-library',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Navigation-Photos-Options',
    },
    localeLabelKey: 'components.menu.items.content-library',
    link: `/content-library`,
    requiredPermissionsToDisplay: [PERMISSIONS.CONTENT_LIBRARY.VIEW],
    requiredPermissionsToUnlock: [PERMISSIONS.CONTENT_LIBRARY.VIEW],
    mobileLocation: 'main',
  },
  {
    id: 'stats',
    Icon,
    iconProps: {
      size: 'medium',
      appearance: 'neutral',
      name: 'Navigation-Statistics',
    },
    localeLabelKey: 'components.menu.items.stats',
    link: `/stats`,
    requiredPermissionsToDisplay: [PERMISSIONS.STATS.VIEW],
    requiredPermissionsToUnlock: [PERMISSIONS.STATS.VIEW],
    mobileLocation: 'submenu',
  },
  {
    id: 'branded-content',
    Icon,
    iconProps: {size: '', appearance: '', name: ''},
    localeLabelKey: 'components.menu.items.bca-partnerships',
    link: `/partnerships`,
    requiredPermissionsToDisplay: [],
    requiredPermissionsToUnlock: [],
    customIsDisplayedFunc: ({currentBrand}: any) =>
      currentBrand?.brandInstagramBusinessAccount?.state === 'active',
    mobileLocation: 'main',
  },
];

export default MENU_ITEMS;
