import dayjs from 'dayjs';
import React from 'react';
import {
  Body,
  designSystemToken,
  Headline,
  Icon,
  Label,
  Tooltip,
} from '@lightricks/react-design-system';
import getPlatformFromIdentityProvider from '@/utils/getIPlatformFromIdentityProvider';
import translate from '@/utils/translate';
import {US_SHORT_MONTH_SHORT_DAY_YEAR_FORMAT_WITH_SEPARATOR} from '@/config/timeFormats';
import {SocialNetwork} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import Link from '@/components/link';
import SocialNetworkIcon from '@/components/social-network-icon';
import CREATOR_VETTING_REPORT_TEST_IDS from '../../testIds';
import styles from './ScannedPlatforms.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.scanned-platforms';

const verifiedIcon = '/assets/svg/verified-icon.svg';

interface Platform extends SocialNetwork {
  trackAction?: (actionName: string, actionValue: string) => void;
  tooltip?: React.ReactNode;
  showVerifiedBadge?: boolean;
}

interface ScannedPlatformsProps {
  platforms: Platform[];
  trackAction: (actionName: string, actionValue: string) => void;
  hasBackgroundCheckResults: boolean;
}

const BACKGROUND_CHECK = {
  provider: 'google',
  handle: 'Google Search',
  profilePageUrl: '',
  unlinkedAt: null,
  isVerified: false,
  isPrivate: false,
};

const SOCIAL_NETWORK_ICON_SIZE = 18;

function ScannedPlatformIcon({id, color}: {id: string; color: string}) {
  return (
    <div
      className={styles.scannedPlatformIconContainer}
      style={{borderColor: color}}
    >
      <SocialNetworkIcon
        name={id}
        width={SOCIAL_NETWORK_ICON_SIZE}
        height={SOCIAL_NETWORK_ICON_SIZE}
        color={color}
      />
    </div>
  );
}

function PrivateProfile() {
  return (
    <div className={styles.privateProfile}>
      <Icon
        size="small"
        appearance="neutral"
        name="Features-Eyes-Hide"
        color={designSystemToken('semantic.fg.secondary')}
      />
      <Body size="sm" color={designSystemToken('semantic.fg.secondary')}>
        {translate('Private')}
      </Body>
    </div>
  );
}

const getDisconnectDate = (date: Date) => {
  return (
    <Label size="sm" color={designSystemToken('semantic.fg.secondary')}>
      {translate(`${TRANSLATION_PREFIX}.creator-disconnected`, {
        date: dayjs(date).format(
          US_SHORT_MONTH_SHORT_DAY_YEAR_FORMAT_WITH_SEPARATOR
        ),
      })}
    </Label>
  );
};

function ScannedPlatform(props: Platform) {
  const {
    provider,
    handle,
    profilePageUrl,
    unlinkedAt,
    isPrivate,
    trackAction,
    tooltip,
    showVerifiedBadge,
  } = props;

  const getHandleLabel = () => {
    const label = <Body size="md">{handle}</Body>;
    if (profilePageUrl) {
      return (
        <Link
          keepBrandIdIfPresent={false}
          to={profilePageUrl}
          target="_blank"
          onClick={() =>
            trackAction?.('Open social network page', profilePageUrl)
          }
        >
          {label}
        </Link>
      );
    }
    return label;
  };

  return (
    <div className={styles.scannedPlatform}>
      <Tooltip
        className={styles.tooltip}
        title={tooltip}
        placement="right"
        disableHoverListener={!tooltip}
        arrow
      >
        <div className={styles.wrapper}>
          <div className={styles.platformIcon}>
            <ScannedPlatformIcon
              id={provider}
              color={designSystemToken(
                `semantic.fg.${isPrivate ? 'tertiary' : 'primary'}`
              )}
            />
          </div>
          <div className={styles.platformInfo}>
            <div
              className={`${styles.handle} ${
                profilePageUrl ? styles.url : ''
              } ${isPrivate ? styles.private : ''}`}
            >
              {getHandleLabel()}
              <Conditional condition={!!showVerifiedBadge}>
                <img src={verifiedIcon} alt="verified" />
              </Conditional>
            </div>
            <Conditional condition={isPrivate}>
              <PrivateProfile />
            </Conditional>
            <Conditional condition={!!unlinkedAt && !isPrivate}>
              {getDisconnectDate(unlinkedAt as Date)}
            </Conditional>
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

function getTooltipContent({
  provider,
  unlinkedAt,
  isVerified,
  isPrivate,
}: Partial<SocialNetwork>) {
  if (isPrivate) {
    return (
      <Body size="md" color={designSystemToken('semantic.fg.primary')}>
        {translate('Private account with no access to content')}
      </Body>
    );
  }
  if (unlinkedAt) {
    return null;
  }
  if (!isVerified) {
    return (
      <Body size="md" color={designSystemToken('semantic.fg.primary')}>
        {translate('Public account scanned using third-party data')}
      </Body>
    );
  }

  return (
    <div className={styles.verifiedTooltip}>
      <Headline size="xxs" className={styles.headline}>
        {translate('Connected to Popular Pays')}
      </Headline>
      <ul className={styles.items}>
        <Body size="md" color={designSystemToken('semantic.fg.primary')}>
          <li>{translate('First-party data integration')}</li>
        </Body>
        <li>
          <Body size="md" color={designSystemToken('semantic.fg.primary')}>
            {translate(
              provider!.includes('instagram')
                ? 'Access to public and private content, including Stories'
                : 'Access to public and private content'
            )}
          </Body>
        </li>
      </ul>
    </div>
  );
}

function mapSocialNetworkToPlatform(
  scannedPlatform: SocialNetwork,
  hasBackgroundCheckResults: boolean
): Platform {
  let tooltip = null;
  let {handle} = scannedPlatform;
  if (scannedPlatform.provider === 'google') {
    tooltip = (
      <Body size="md" color={designSystemToken('semantic.fg.primary')}>
        {translate(
          hasBackgroundCheckResults
            ? "Flagged based on the creator's name"
            : "No issues flagged based on the creator's name"
        )}
      </Body>
    );
  } else {
    handle = `@${handle}`;
    tooltip = getTooltipContent({
      provider: scannedPlatform.provider,
      unlinkedAt: scannedPlatform.unlinkedAt,
      isVerified: scannedPlatform.isVerified,
      isPrivate: scannedPlatform.isPrivate,
    });
  }

  return {
    ...scannedPlatform,
    handle,
    tooltip,
    showVerifiedBadge:
      scannedPlatform.isVerified && !scannedPlatform.unlinkedAt,
  };
}

function ScannedPlatforms(props: ScannedPlatformsProps) {
  const {platforms, trackAction, hasBackgroundCheckResults} = props;
  const platformsWithBackgroundCheck = platforms.concat(BACKGROUND_CHECK);
  return (
    <div
      className={styles.scannedPlatformsContainer}
      data-testid={CREATOR_VETTING_REPORT_TEST_IDS.SCANNED_PLATFORMS}
    >
      <Headline size="xs">{translate(`${TRANSLATION_PREFIX}.title`)}</Headline>
      <div className={styles.platformsContainer}>
        {platformsWithBackgroundCheck.map((platform) => (
          <ScannedPlatform
            key={platform.provider}
            {...mapSocialNetworkToPlatform(platform, hasBackgroundCheckResults)}
            provider={getPlatformFromIdentityProvider(platform.provider)}
            trackAction={trackAction}
          />
        ))}
      </div>
    </div>
  );
}

export default ScannedPlatforms;
